import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';

export type HTMLElementOrNull = HTMLElement | null;
export type RefElementOrNull<T> = T | null;
export type CallbackRef = (node: HTMLElementOrNull) => unknown;
export type AnyRef = CallbackRef | MutableRefObject<HTMLElementOrNull>;

interface UseInViewRefOptions extends IntersectionObserverInit {
  root?: HTMLElementOrNull;
  once?: boolean;
}
const config: UseInViewRefOptions = {
  // The root element to observe. Defaults to `null`.
  root: null,
  // The margin around the root element. Defaults to `0px 0px 0px 0px`.
  rootMargin: '0px 0px 0px 0px',
  // The threshold for the intersection. Defaults to `0`.
  threshold: 0,
  // Whether the observer should only trigger once. Defaults to `true`.
  once: true,
};

/**
 * React hook that returns a callback ref and two booleans indicating whether the
 * element is currently in view, and whether it has been in view at least once.
 *
 * The hook takes an options object with four properties:
 *
 * - `root`: The root element to observe. Defaults to `null`.
 * - `rootMargin`: The margin around the root element. Defaults to `0px 0px 0px 0px`.
 * - `threshold`: The threshold for the intersection. Defaults to `0`.
 * - `once`: Whether the observer should only trigger once. Defaults to `true`.
 *
 * The hook returns an array with three elements:
 *
 * - A callback ref that can be used to set the element to observe.
 * - A boolean indicating whether the element is currently in view.
 * - A boolean indicating whether the element has been in view at least once.
 */
export function useInViewRef(
  options?: UseInViewRefOptions
): [CallbackRef, boolean, boolean, HTMLElementOrNull] {
  const { root, rootMargin, threshold, once } = { ...config, ...options };

  const [node, setNode] = useState<HTMLElementOrNull>(null);
  const [inView, setInView] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false);

  useEffect(() => {
    if (node) {
      const observer = new IntersectionObserver(
        (entries, observerRef) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
              if (!isFirstRender) {
                setIsFirstRender(true);
              }

              if (once) {
                observerRef.unobserve(node);
              }
            } else {
              setInView(false);
            }
          });
        },
        {
          root,
          rootMargin,
          threshold,
        }
      );

      observer.observe(node);
      return () => {
        observer.disconnect();
      };
    }
  }, [node, root, rootMargin, threshold, once, isFirstRender]);

  const ref = useCallback((node: HTMLElementOrNull) => {
    setNode(node);
  }, []);

  return [ref, inView, isFirstRender, node];
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      setWindowSize({
        width: vw,
        height: vh,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

export function useHover() {
  const [isHover, setIsHover] = useState<boolean>(false);
  useEffect(() => {
    setIsHover(window.matchMedia('(hover: hover)').matches);
  }, []);
  return isHover;
}

export function getRefValue<C>(ref: RefObject<C>) {
  return ref.current as C;
}

type Dimensions = {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  x?: number;
  y?: number;
  right?: number;
  bottom?: number;
};

function getDimensionObject(node: HTMLElement): Dimensions {
  const rect: DOMRect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : (rect as DOMRect).top,
    left: 'y' in rect ? rect.y : (rect as DOMRect).left,
    x: 'x' in rect ? rect.x : (rect as DOMRect).left,
    y: 'y' in rect ? rect.y : (rect as DOMRect).top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

export function useDimensions(
  data = null,
  liveMeasure = true
): [CallbackRef, Dimensions, HTMLElementOrNull] {
  const [dimensions, setDimensions] = useState<{
    width?: number;
    height?: number;
    top?: number;
    left?: number;
    x?: number;
    y?: number;
    right?: number;
    bottom?: number;
  }>({});
  const [node, setNode] = useState<HTMLElementOrNull>(null);

  const ref = useCallback((node: HTMLElementOrNull) => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();

      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);

        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, data]);

  return [ref, dimensions, node];
}

const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false);

  useEffect(() => {
    const checkTouchDevice = () => {
      setIsTouchDevice(window.matchMedia('(pointer: coarse)').matches);
    };
    window.addEventListener('resize', checkTouchDevice);

    // Initial check
    checkTouchDevice();

    return () => {
      window.removeEventListener('resize', checkTouchDevice);
    };
  }, []);

  return isTouchDevice;
};

export default useIsTouchDevice;
