import Head from 'next/head';

import { IMAGE_WIDTHS } from '@/components/shared/media/image/Image.constants';
import {
  DEFAULT_IMAGE,
  ImageLoadingType,
  ImageOptions,
  MediaConditions,
  Source,
} from '@/components/shared/media/image/Image.props';

export const isSvg = (src: string = ''): boolean => {
  return src.endsWith('.svg');
};

export const generateSrcSet = (
  src: string,
  options: ImageOptions = {}
): string => {
  return IMAGE_WIDTHS.map((width) => {
    const url = createImageQueryParams(src, { ...options, width }, true);
    return `${url} ${width}w`;
  }).join(', ');
};

export const createImageQueryParams = (
  src: string = '',
  options: ImageOptions = {},
  useSrcSetPattern: boolean = false
): string => {
  const {
    width,
    height,
    crop,
    fit,
    trim,
    blur,
    quality = 75,
    dpr = 1,
  } = options;

  if (
    !src ||
    src === DEFAULT_IMAGE ||
    src.startsWith('data:image/') ||
    src.endsWith('.svg')
  ) {
    return src;
  }

  const imageParams = new URLSearchParams({
    format: 'pjpg',
    auto: 'webp',
    quality: `${quality}`,
  });

  if (width) imageParams.set('width', `${width}`);
  if (height) imageParams.set('height', `${height}`);
  if (crop) imageParams.set('crop', `${crop}`);
  if (fit) imageParams.set('fit', `${fit}`);
  if (trim) imageParams.set('trim', `${trim}`);
  if (blur) imageParams.set('blur', `${blur}`);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!useSrcSetPattern && width >= 1920) {
    imageParams.set('dpr', '1.5');
  } else if (dpr > 1) {
    imageParams.set('dpr', `${dpr}`);
  }

  return `${src}?${imageParams.toString()}`;
};

export const createImgSrcSetElement = (
  src: string,
  options: ImageOptions = {},
  inView: boolean,
  loading: ImageLoadingType
): string | null => {
  if (!src) {
    return null;
  }

  if (loading === 'lazy' && !inView) {
    return null;
  }

  if (isSvg(src)) {
    return null;
  }
  if (src.endsWith('.svg')) return null;

  return generateSrcSet(src, options);
};

export const createSrcSet = (src: string, options: ImageOptions): string => {
  if (!src) return '';

  const srcset1x = createImageQueryParams(src, { ...options, dpr: 1.5 });
  const srcset2x = createImageQueryParams(src, { ...options, dpr: 2 });
  let srcsetString = `${srcset1x} 1x, ${srcset2x} 2x`;

  if (options?.width && options.width < 480) {
    const srcset3x = createImageQueryParams(src, { ...options, dpr: 2.5 });
    srcsetString += `, ${srcset3x} 3x`;
  }

  return srcsetString;
};

export const createMediaCondition = (media: MediaConditions): string => {
  const conditions: string[] = [];
  if (media?.minWidth) conditions.push(`(min-width: ${media.minWidth}px)`);
  if (media?.maxWidth) conditions.push(`(max-width: ${media.maxWidth}px)`);
  if (media?.orientation)
    conditions.push(`(orientation: ${media.orientation})`);
  return conditions.join(' and ');
};

export const createSourceElement = (
  source: Source,
  index: number,
  inView: boolean,
  loading: ImageLoadingType
) => {
  if (!source || !source.srcset || (loading === 'lazy' && !inView)) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const srcSet = createSrcSet(source.srcset, source.options);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mediaCondition = createMediaCondition(source.media);

  return (
    <source
      key={index}
      srcSet={srcSet}
      {...(mediaCondition && { media: mediaCondition })}
      data-device={source.device}
    />
  );
};

export const determineImageSrc = (
  src: string,
  defaultSrc: string,
  inView: boolean,
  loading: ImageLoadingType
): string => {
  if (!src) return defaultSrc;

  return loading === 'eager' || inView ? src : defaultSrc;
};

export const createPreloadLinks = (
  src: string,
  sources?: Source[],
  srcSetOptions?: ImageOptions,
  sizes?: string,
  useSrcSetPattern: boolean = false
) => {
  if (!src) return null;

  const isSvgImage = isSvg(src);

  const preloadLinks = sources
    ? sources.map((source, index) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const srcSet = createSrcSet(source.srcset, source.options);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const mediaCondition = createMediaCondition(source.media);
        const isSvgImageSrcset = isSvg(source.srcset);

        return (
          <link
            key={`preload-${src}-${index}`}
            rel="preload"
            as="image"
            href={source.srcset}
            imageSrcSet={srcSet}
            media={mediaCondition || undefined}
            type={isSvgImageSrcset ? 'image/svg+xml' : undefined}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority="high"
          />
        );
      })
    : [
        <link
          key={`preload-${src}`}
          rel="preload"
          as="image"
          href={src}
          imageSrcSet={
            useSrcSetPattern && !isSvgImage
              ? generateSrcSet(src, srcSetOptions)
              : undefined
          }
          imageSizes={!isSvgImage ? sizes || undefined : undefined}
          type={isSvgImage ? 'image/svg+xml' : undefined}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          fetchpriority="high"
        />,
      ];

  return preloadLinks.length ? <Head>{preloadLinks}</Head> : null;
};
