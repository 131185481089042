import React, { ElementType, forwardRef, ReactNode } from 'react';

import { CSS, styled } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

const BoxStyled = styled('div', {
  variants: {
    backgroundColor: {
      transparent: {
        backgroundColor: '$transparent',
      },
      white: {
        backgroundColor: '$white',
      },
      black: {
        backgroundColor: '$black',
      },
      red: {
        backgroundColor: '$primary',
      },
      gray100: {
        backgroundColor: '$gray100',
      },
      gray200: {
        backgroundColor: '$gray200',
      },
      gray300: {
        backgroundColor: '$gray300',
      },
      gray400: {
        backgroundColor: '$gray400',
      },
      gray500: {
        backgroundColor: '$gray500',
      },
      gray600: {
        backgroundColor: '$gray600',
      },
      gray700: {
        backgroundColor: '$gray700',
      },
      gray800: {
        backgroundColor: '$gray800',
      },
      gray900: {
        backgroundColor: '$gray900',
      },
      gray1100: {
        backgroundColor: '$gray1100',
      },
      backdrop: {
        backgroundColor: '$backdrop70',
      },
    },
    border: {
      none: {
        border: 'none',
      },
      '1': {
        borderWidth: '1px',
        borderStyle: 'solid',
      },
      '2': {
        borderWidth: '2px',
        borderStyle: 'solid',
      },
      't-1': {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
      },
      'b-1': {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
      },
      'x-1': {
        borderLeftWidth: '1px',
        borderLeftStyle: 'solid',
        borderRightWidth: '1px',
        borderRightStyle: 'solid',
      },
      'y-1': {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
      },
    },
    borderColor: {
      transparent: {
        borderColor: '$transparent',
      },
      white: {
        borderColor: '$white',
      },
      black: {
        borderColor: '$black',
      },
      gray300: {
        borderColor: '$gray300',
      },
      gray400: {
        borderColor: '$gray400',
      },
    },
    rounded: {
      '0': {
        borderRadius: '$rounded-0',
      },
      '0-5': {
        borderRadius: '$rounded-0-5',
      },
      '1': {
        borderRadius: '$rounded-1',
      },
      '1-5': {
        borderRadius: '$rounded-1-5',
      },
      '2': {
        borderRadius: '$rounded-2',
      },
      '3': {
        borderRadius: '$rounded-3',
      },
      '4': {
        borderRadius: '$rounded-4',
      },
      '6': {
        borderRadius: '$rounded-6',
      },
      '8': {
        borderRadius: '$rounded-8',
      },
      full: {
        borderRadius: '$rounded-full',
      },
    },
    margin: {
      '0': {
        margin: '$space-0',
      },
      px: {
        margin: '$space-px',
      },
      '0-5': {
        margin: '$space-0-5',
      },
      '1': {
        margin: '$space-1',
      },
      '1-5': {
        margin: '$space-1-5',
      },
      '2': {
        margin: '$space-2',
      },
      '3': {
        margin: '$space-3',
      },
      '4': {
        margin: '$space-4',
      },
      '5': {
        margin: '$space-5',
      },
      '6': {
        margin: '$space-6',
      },
      '7': {
        margin: '$space-7',
      },
      '8': {
        margin: '$space-8',
      },
      '9': {
        margin: '$space-9',
      },
      '10': {
        margin: '$space-10',
      },
      '11': {
        margin: '$space-11',
      },
      '12': {
        margin: '$space-12',
      },
      '14': {
        margin: '$space-14',
      },
      '16': {
        margin: '$space-16',
      },
      '20': {
        margin: '$space-20',
      },
      '24': {
        margin: '$space-24',
      },
      '28': {
        margin: '$space-28',
      },
      '32': {
        margin: '$space-32',
      },
      '36': {
        margin: '$space-36',
      },
      '40': {
        margin: '$space-40',
      },
      '44': {
        margin: '$space-44',
      },
      '48': {
        margin: '$space-48',
      },
      '52': {
        margin: '$space-52',
      },
      '56': {
        margin: '$space-56',
      },
      '60': {
        margin: '$space-60',
      },
      '64': {
        margin: '$space-64',
      },
      '72': {
        margin: '$space-72',
      },
      '80': {
        margin: '$space-80',
      },
      '96': {
        margin: '$space-96',
      },
      auto: {
        margin: '$space-auto',
      },
    },
    marginX: {
      '0': {
        marginLeft: '$space-0',
        marginRight: '$space-0',
      },
      px: {
        marginLeft: '$space-px',
        marginRight: '$space-px',
      },
      '0-5': {
        marginLeft: '$space-0-5',
        marginRight: '$space-0-5',
      },
      '1': {
        marginLeft: '$space-1',
        marginRight: '$space-1',
      },
      '1-5': {
        marginLeft: '$space-1-5',
        marginRight: '$space-1-5',
      },
      '2': {
        marginLeft: '$space-2',
        marginRight: '$space-2',
      },
      '3': {
        marginLeft: '$space-3',
        marginRight: '$space-3',
      },
      '4': {
        marginLeft: '$space-4',
        marginRight: '$space-4',
      },
      '5': {
        marginLeft: '$space-5',
        marginRight: '$space-5',
      },
      '6': {
        marginLeft: '$space-6',
        marginRight: '$space-6',
      },
      '7': {
        marginLeft: '$space-7',
        marginRight: '$space-7',
      },
      '8': {
        marginLeft: '$space-8',
        marginRight: '$space-8',
      },
      '9': {
        marginLeft: '$space-9',
        marginRight: '$space-9',
      },
      '10': {
        marginLeft: '$space-10',
        marginRight: '$space-10',
      },
      '11': {
        marginLeft: '$space-11',
        marginRight: '$space-11',
      },
      '12': {
        marginLeft: '$space-12',
        marginRight: '$space-12',
      },
      '14': {
        marginLeft: '$space-14',
        marginRight: '$space-14',
      },
      '16': {
        marginLeft: '$space-16',
        marginRight: '$space-16',
      },
      '20': {
        marginLeft: '$space-20',
        marginRight: '$space-20',
      },
      '24': {
        marginLeft: '$space-24',
        marginRight: '$space-24',
      },
      '28': {
        marginLeft: '$space-28',
        marginRight: '$space-28',
      },
      '32': {
        marginLeft: '$space-32',
        marginRight: '$space-32',
      },
      '36': {
        marginLeft: '$space-36',
        marginRight: '$space-36',
      },
      '40': {
        marginLeft: '$space-40',
        marginRight: '$space-40',
      },
      '44': {
        marginLeft: '$space-44',
        marginRight: '$space-44',
      },
      '48': {
        marginLeft: '$space-48',
        marginRight: '$space-48',
      },
      '52': {
        marginLeft: '$space-52',
        marginRight: '$space-52',
      },
      '56': {
        marginLeft: '$space-56',
        marginRight: '$space-56',
      },
      '60': {
        marginLeft: '$space-60',
        marginRight: '$space-60',
      },
      '64': {
        marginLeft: '$space-64',
        marginRight: '$space-64',
      },
      '72': {
        marginLeft: '$space-72',
        marginRight: '$space-72',
      },
      '80': {
        marginLeft: '$space-80',
        marginRight: '$space-80',
      },
      '96': {
        marginLeft: '$space-96',
        marginRight: '$space-96',
      },
      auto: {
        marginLeft: '$space-auto',
        marginRight: '$space-auto',
      },
    },
    marginY: {
      '0': {
        marginTop: '$space-0',
        marginBottom: '$space-0',
      },
      px: {
        marginTop: '$space-px',
        marginBottom: '$space-px',
      },
      '0-5': {
        marginTop: '$space-0-5',
        marginBottom: '$space-0-5',
      },
      '1': {
        marginTop: '$space-1',
        marginBottom: '$space-1',
      },
      '1-5': {
        marginTop: '$space-1-5',
        marginBottom: '$space-1-5',
      },
      '2': {
        marginTop: '$space-2',
        marginBottom: '$space-2',
      },
      '3': {
        marginTop: '$space-3',
        marginBottom: '$space-3',
      },
      '4': {
        marginTop: '$space-4',
        marginBottom: '$space-4',
      },
      '5': {
        marginTop: '$space-5',
        marginBottom: '$space-5',
      },
      '6': {
        marginTop: '$space-6',
        marginBottom: '$space-6',
      },
      '7': {
        marginTop: '$space-7',
        marginBottom: '$space-7',
      },
      '8': {
        marginTop: '$space-8',
        marginBottom: '$space-8',
      },
      '9': {
        marginTop: '$space-9',
        marginBottom: '$space-9',
      },
      '10': {
        marginTop: '$space-10',
        marginBottom: '$space-10',
      },
      '11': {
        marginTop: '$space-11',
        marginBottom: '$space-11',
      },
      '12': {
        marginTop: '$space-12',
        marginBottom: '$space-12',
      },
      '14': {
        marginTop: '$space-14',
        marginBottom: '$space-14',
      },
      '16': {
        marginTop: '$space-16',
        marginBottom: '$space-16',
      },
      '20': {
        marginTop: '$space-20',
        marginBottom: '$space-20',
      },
      '24': {
        marginTop: '$space-24',
        marginBottom: '$space-24',
      },
      '28': {
        marginTop: '$space-28',
        marginBottom: '$space-28',
      },
      '32': {
        marginTop: '$space-32',
        marginBottom: '$space-32',
      },
      '36': {
        marginTop: '$space-36',
        marginBottom: '$space-36',
      },
      '40': {
        marginTop: '$space-40',
        marginBottom: '$space-40',
      },
      '44': {
        marginTop: '$space-44',
        marginBottom: '$space-44',
      },
      '48': {
        marginTop: '$space-48',
        marginBottom: '$space-48',
      },
      '52': {
        marginTop: '$space-52',
        marginBottom: '$space-52',
      },
      '56': {
        marginTop: '$space-56',
        marginBottom: '$space-56',
      },
      '60': {
        marginTop: '$space-60',
        marginBottom: '$space-60',
      },
      '64': {
        marginTop: '$space-64',
        marginBottom: '$space-64',
      },
      '72': {
        marginTop: '$space-72',
        marginBottom: '$space-72',
      },
      '80': {
        marginTop: '$space-80',
        marginBottom: '$space-80',
      },
      '96': {
        marginTop: '$space-96',
        marginBottom: '$space-96',
      },
      auto: {
        marginTop: '$space-auto',
        marginBottom: '$space-auto',
      },
    },
    marginLeft: {
      '0': {
        marginLeft: '$space-0',
      },
      px: {
        marginLeft: '$space-px',
      },
      '0-5': {
        marginLeft: '$space-0-5',
      },
      '1': {
        marginLeft: '$space-1',
      },
      '1-5': {
        marginLeft: '$space-1-5',
      },
      '2': {
        marginLeft: '$space-2',
      },
      '3': {
        marginLeft: '$space-3',
      },
      '4': {
        marginLeft: '$space-4',
      },
      '5': {
        marginLeft: '$space-5',
      },
      '6': {
        marginLeft: '$space-6',
      },
      '7': {
        marginLeft: '$space-7',
      },
      '8': {
        marginLeft: '$space-8',
      },
      '9': {
        marginLeft: '$space-9',
      },
      '10': {
        marginLeft: '$space-10',
      },
      '11': {
        marginLeft: '$space-11',
      },
      '12': {
        marginLeft: '$space-12',
      },
      '14': {
        marginLeft: '$space-14',
      },
      '16': {
        marginLeft: '$space-16',
      },
      '20': {
        marginLeft: '$space-20',
      },
      '24': {
        marginLeft: '$space-24',
      },
      '28': {
        marginLeft: '$space-28',
      },
      '32': {
        marginLeft: '$space-32',
      },
      '36': {
        marginLeft: '$space-36',
      },
      '40': {
        marginLeft: '$space-40',
      },
      '44': {
        marginLeft: '$space-44',
      },
      '48': {
        marginLeft: '$space-48',
      },
      '52': {
        marginLeft: '$space-52',
      },
      '56': {
        marginLeft: '$space-56',
      },
      '60': {
        marginLeft: '$space-60',
      },
      '64': {
        marginLeft: '$space-64',
      },
      '72': {
        marginLeft: '$space-72',
      },
      '80': {
        marginLeft: '$space-80',
      },
      '96': {
        marginLeft: '$space-96',
      },
      auto: {
        marginLeft: '$space-auto',
      },
    },
    marginRight: {
      '0': {
        marginRight: '$space-0',
      },
      px: {
        marginRight: '$space-px',
      },
      '0-5': {
        marginRight: '$space-0-5',
      },
      '1': {
        marginRight: '$space-1',
      },
      '1-5': {
        marginRight: '$space-1-5',
      },
      '2': {
        marginRight: '$space-2',
      },
      '3': {
        marginRight: '$space-3',
      },
      '4': {
        marginRight: '$space-4',
      },
      '5': {
        marginRight: '$space-5',
      },
      '6': {
        marginRight: '$space-6',
      },
      '7': {
        marginRight: '$space-7',
      },
      '8': {
        marginRight: '$space-8',
      },
      '9': {
        marginRight: '$space-9',
      },
      '10': {
        marginRight: '$space-10',
      },
      '11': {
        marginRight: '$space-11',
      },
      '12': {
        marginRight: '$space-12',
      },
      '14': {
        marginRight: '$space-14',
      },
      '16': {
        marginRight: '$space-16',
      },
      '20': {
        marginRight: '$space-20',
      },
      '24': {
        marginRight: '$space-24',
      },
      '28': {
        marginRight: '$space-28',
      },
      '32': {
        marginRight: '$space-32',
      },
      '36': {
        marginRight: '$space-36',
      },
      '40': {
        marginRight: '$space-40',
      },
      '44': {
        marginRight: '$space-44',
      },
      '48': {
        marginRight: '$space-48',
      },
      '52': {
        marginRight: '$space-52',
      },
      '56': {
        marginRight: '$space-56',
      },
      '60': {
        marginRight: '$space-60',
      },
      '64': {
        marginRight: '$space-64',
      },
      '72': {
        marginRight: '$space-72',
      },
      '80': {
        marginRight: '$space-80',
      },
      '96': {
        marginRight: '$space-96',
      },
      auto: {
        marginRight: '$space-auto',
      },
    },
    marginTop: {
      '0': {
        marginTop: '$space-0',
      },
      px: {
        marginTop: '$space-px',
      },
      '0-5': {
        marginTop: '$space-0-5',
      },
      '1': {
        marginTop: '$space-1',
      },
      '1-5': {
        marginTop: '$space-1-5',
      },
      '2': {
        marginTop: '$space-2',
      },
      '3': {
        marginTop: '$space-3',
      },
      '4': {
        marginTop: '$space-4',
      },
      '5': {
        marginTop: '$space-5',
      },
      '6': {
        marginTop: '$space-6',
      },
      '7': {
        marginTop: '$space-7',
      },
      '8': {
        marginTop: '$space-8',
      },
      '9': {
        marginTop: '$space-9',
      },
      '10': {
        marginTop: '$space-10',
      },
      '11': {
        marginTop: '$space-11',
      },
      '12': {
        marginTop: '$space-12',
      },
      '14': {
        marginTop: '$space-14',
      },
      '16': {
        marginTop: '$space-16',
      },
      '20': {
        marginTop: '$space-20',
      },
      '24': {
        marginTop: '$space-24',
      },
      '28': {
        marginTop: '$space-28',
      },
      '32': {
        marginTop: '$space-32',
      },
      '36': {
        marginTop: '$space-36',
      },
      '40': {
        marginTop: '$space-40',
      },
      '44': {
        marginTop: '$space-44',
      },
      '48': {
        marginTop: '$space-48',
      },
      '52': {
        marginTop: '$space-52',
      },
      '56': {
        marginTop: '$space-56',
      },
      '60': {
        marginTop: '$space-60',
      },
      '64': {
        marginTop: '$space-64',
      },
      '72': {
        marginTop: '$space-72',
      },
      '80': {
        marginTop: '$space-80',
      },
      '96': {
        marginTop: '$space-96',
      },
      auto: {
        marginTop: '$space-auto',
      },
    },
    marginBottom: {
      '0': {
        marginBottom: '$space-0',
      },
      px: {
        marginBottom: '$space-px',
      },
      '0-5': {
        marginBottom: '$space-0-5',
      },
      '1': {
        marginBottom: '$space-1',
      },
      '1-5': {
        marginBottom: '$space-1-5',
      },
      '2': {
        marginBottom: '$space-2',
      },
      '3': {
        marginBottom: '$space-3',
      },
      '4': {
        marginBottom: '$space-4',
      },
      '5': {
        marginBottom: '$space-5',
      },
      '6': {
        marginBottom: '$space-6',
      },
      '7': {
        marginBottom: '$space-7',
      },
      '8': {
        marginBottom: '$space-8',
      },
      '9': {
        marginBottom: '$space-9',
      },
      '10': {
        marginBottom: '$space-10',
      },
      '11': {
        marginBottom: '$space-11',
      },
      '12': {
        marginBottom: '$space-12',
      },
      '14': {
        marginBottom: '$space-14',
      },
      '16': {
        marginBottom: '$space-16',
      },
      '20': {
        marginBottom: '$space-20',
      },
      '24': {
        marginBottom: '$space-24',
      },
      '28': {
        marginBottom: '$space-28',
      },
      '32': {
        marginBottom: '$space-32',
      },
      '36': {
        marginBottom: '$space-36',
      },
      '40': {
        marginBottom: '$space-40',
      },
      '44': {
        marginBottom: '$space-44',
      },
      '48': {
        marginBottom: '$space-48',
      },
      '52': {
        marginBottom: '$space-52',
      },
      '56': {
        marginBottom: '$space-56',
      },
      '60': {
        marginBottom: '$space-60',
      },
      '64': {
        marginBottom: '$space-64',
      },
      '72': {
        marginBottom: '$space-72',
      },
      '80': {
        marginBottom: '$space-80',
      },
      '96': {
        marginBottom: '$space-96',
      },
      auto: {
        marginBottom: '$space-auto',
      },
    },
    padding: {
      '0': {
        padding: '$space-0',
      },
      px: {
        padding: '$space-px',
      },
      '0-5': {
        padding: '$space-0-5',
      },
      '1': {
        padding: '$space-1',
      },
      '1-5': {
        padding: '$space-1-5',
      },
      '2': {
        padding: '$space-2',
      },
      '3': {
        padding: '$space-3',
      },
      '4': {
        padding: '$space-4',
      },
      '5': {
        padding: '$space-5',
      },
      '6': {
        padding: '$space-6',
      },
      '7': {
        padding: '$space-7',
      },
      '8': {
        padding: '$space-8',
      },
      '9': {
        padding: '$space-9',
      },
      '10': {
        padding: '$space-10',
      },
      '11': {
        padding: '$space-11',
      },
      '12': {
        padding: '$space-12',
      },
      '14': {
        padding: '$space-14',
      },
      '16': {
        padding: '$space-16',
      },
      '20': {
        padding: '$space-20',
      },
      '24': {
        padding: '$space-24',
      },
      '28': {
        padding: '$space-28',
      },
      '32': {
        padding: '$space-32',
      },
      '36': {
        padding: '$space-36',
      },
      '40': {
        padding: '$space-40',
      },
      '44': {
        padding: '$space-44',
      },
      '48': {
        padding: '$space-48',
      },
      '52': {
        padding: '$space-52',
      },
      '56': {
        padding: '$space-56',
      },
      '60': {
        padding: '$space-60',
      },
      '64': {
        padding: '$space-64',
      },
      '72': {
        padding: '$space-72',
      },
      '80': {
        padding: '$space-80',
      },
      '96': {
        padding: '$space-96',
      },
    },
    paddingX: {
      '0': {
        paddingLeft: '$space-0',
        paddingRight: '$space-0',
      },
      px: {
        paddingLeft: '$space-px',
        paddingRight: '$space-px',
      },
      '0-5': {
        paddingLeft: '$space-0-5',
        paddingRight: '$space-0-5',
      },
      '1': {
        paddingLeft: '$space-1',
        paddingRight: '$space-1',
      },
      '1-5': {
        paddingLeft: '$space-1-5',
        paddingRight: '$space-1-5',
      },
      '2': {
        paddingLeft: '$space-2',
        paddingRight: '$space-2',
      },
      '3': {
        paddingLeft: '$space-3',
        paddingRight: '$space-3',
      },
      '4': {
        paddingLeft: '$space-4',
        paddingRight: '$space-4',
      },
      '5': {
        paddingLeft: '$space-5',
        paddingRight: '$space-5',
      },
      '6': {
        paddingLeft: '$space-6',
        paddingRight: '$space-6',
      },
      '7': {
        paddingLeft: '$space-7',
        paddingRight: '$space-7',
      },
      '8': {
        paddingLeft: '$space-8',
        paddingRight: '$space-8',
      },
      '9': {
        paddingLeft: '$space-9',
        paddingRight: '$space-9',
      },
      '10': {
        paddingLeft: '$space-10',
        paddingRight: '$space-10',
      },
      '11': {
        paddingLeft: '$space-11',
        paddingRight: '$space-11',
      },
      '12': {
        paddingLeft: '$space-12',
        paddingRight: '$space-12',
      },
      '14': {
        paddingLeft: '$space-14',
        paddingRight: '$space-14',
      },
      '16': {
        paddingLeft: '$space-16',
        paddingRight: '$space-16',
      },
      '20': {
        paddingLeft: '$space-20',
        paddingRight: '$space-20',
      },
      '24': {
        paddingLeft: '$space-24',
        paddingRight: '$space-24',
      },
      '28': {
        paddingLeft: '$space-28',
        paddingRight: '$space-28',
      },
      '32': {
        paddingLeft: '$space-32',
        paddingRight: '$space-32',
      },
      '36': {
        paddingLeft: '$space-36',
        paddingRight: '$space-36',
      },
      '40': {
        paddingLeft: '$space-40',
        paddingRight: '$space-40',
      },
      '44': {
        paddingLeft: '$space-44',
        paddingRight: '$space-44',
      },
      '48': {
        paddingLeft: '$space-48',
        paddingRight: '$space-48',
      },
      '52': {
        paddingLeft: '$space-52',
        paddingRight: '$space-52',
      },
      '56': {
        paddingLeft: '$space-56',
        paddingRight: '$space-56',
      },
      '60': {
        paddingLeft: '$space-60',
        paddingRight: '$space-60',
      },
      '64': {
        paddingLeft: '$space-64',
        paddingRight: '$space-64',
      },
      '72': {
        paddingLeft: '$space-72',
        paddingRight: '$space-72',
      },
      '80': {
        paddingLeft: '$space-80',
        paddingRight: '$space-80',
      },
      '96': {
        paddingLeft: '$space-96',
        paddingRight: '$space-96',
      },
    },
    paddingY: {
      '0': {
        paddingTop: '$space-0',
        paddingBottom: '$space-0',
      },
      px: {
        paddingTop: '$space-px',
        paddingBottom: '$space-px',
      },
      '0-5': {
        paddingTop: '$space-0-5',
        paddingBottom: '$space-0-5',
      },
      '1': {
        paddingTop: '$space-1',
        paddingBottom: '$space-1',
      },
      '1-5': {
        paddingTop: '$space-1-5',
        paddingBottom: '$space-1-5',
      },
      '2': {
        paddingTop: '$space-2',
        paddingBottom: '$space-2',
      },
      '3': {
        paddingTop: '$space-3',
        paddingBottom: '$space-3',
      },
      '4': {
        paddingTop: '$space-4',
        paddingBottom: '$space-4',
      },
      '5': {
        paddingTop: '$space-5',
        paddingBottom: '$space-5',
      },
      '6': {
        paddingTop: '$space-6',
        paddingBottom: '$space-6',
      },
      '7': {
        paddingTop: '$space-7',
        paddingBottom: '$space-7',
      },
      '8': {
        paddingTop: '$space-8',
        paddingBottom: '$space-8',
      },
      '9': {
        paddingTop: '$space-9',
        paddingBottom: '$space-9',
      },
      '10': {
        paddingTop: '$space-10',
        paddingBottom: '$space-10',
      },
      '11': {
        paddingTop: '$space-11',
        paddingBottom: '$space-11',
      },
      '12': {
        paddingTop: '$space-12',
        paddingBottom: '$space-12',
      },
      '14': {
        paddingTop: '$space-14',
        paddingBottom: '$space-14',
      },
      '16': {
        paddingTop: '$space-16',
        paddingBottom: '$space-16',
      },
      '20': {
        paddingTop: '$space-20',
        paddingBottom: '$space-20',
      },
      '24': {
        paddingTop: '$space-24',
        paddingBottom: '$space-24',
      },
      '28': {
        paddingTop: '$space-28',
        paddingBottom: '$space-28',
      },
      '32': {
        paddingTop: '$space-32',
        paddingBottom: '$space-32',
      },
      '36': {
        paddingTop: '$space-36',
        paddingBottom: '$space-36',
      },
      '40': {
        paddingTop: '$space-40',
        paddingBottom: '$space-40',
      },
      '44': {
        paddingTop: '$space-44',
        paddingBottom: '$space-44',
      },
      '48': {
        paddingTop: '$space-48',
        paddingBottom: '$space-48',
      },
      '52': {
        paddingTop: '$space-52',
        paddingBottom: '$space-52',
      },
      '56': {
        paddingTop: '$space-56',
        paddingBottom: '$space-56',
      },
      '60': {
        paddingTop: '$space-60',
        paddingBottom: '$space-60',
      },
      '64': {
        paddingTop: '$space-64',
        paddingBottom: '$space-64',
      },
      '72': {
        paddingTop: '$space-72',
        paddingBottom: '$space-72',
      },
      '80': {
        paddingTop: '$space-80',
        paddingBottom: '$space-80',
      },
      '96': {
        paddingTop: '$space-96',
        paddingBottom: '$space-96',
      },
    },
    paddingLeft: {
      '0': {
        paddingLeft: '$space-0',
      },
      px: {
        paddingLeft: '$space-px',
      },
      '0-5': {
        paddingLeft: '$space-0-5',
      },
      '1': {
        paddingLeft: '$space-1',
      },
      '1-5': {
        paddingLeft: '$space-1-5',
      },
      '2': {
        paddingLeft: '$space-2',
      },
      '3': {
        paddingLeft: '$space-3',
      },
      '4': {
        paddingLeft: '$space-4',
      },
      '5': {
        paddingLeft: '$space-5',
      },
      '6': {
        paddingLeft: '$space-6',
      },
      '7': {
        paddingLeft: '$space-7',
      },
      '8': {
        paddingLeft: '$space-8',
      },
      '9': {
        paddingLeft: '$space-9',
      },
      '10': {
        paddingLeft: '$space-10',
      },
      '11': {
        paddingLeft: '$space-11',
      },
      '12': {
        paddingLeft: '$space-12',
      },
      '14': {
        paddingLeft: '$space-14',
      },
      '16': {
        paddingLeft: '$space-16',
      },
      '20': {
        paddingLeft: '$space-20',
      },
      '24': {
        paddingLeft: '$space-24',
      },
      '28': {
        paddingLeft: '$space-28',
      },
      '32': {
        paddingLeft: '$space-32',
      },
      '36': {
        paddingLeft: '$space-36',
      },
      '40': {
        paddingLeft: '$space-40',
      },
      '44': {
        paddingLeft: '$space-44',
      },
      '48': {
        paddingLeft: '$space-48',
      },
      '52': {
        paddingLeft: '$space-52',
      },
      '56': {
        paddingLeft: '$space-56',
      },
      '60': {
        paddingLeft: '$space-60',
      },
      '64': {
        paddingLeft: '$space-64',
      },
      '72': {
        paddingLeft: '$space-72',
      },
      '80': {
        paddingLeft: '$space-80',
      },
      '96': {
        paddingLeft: '$space-96',
      },
    },
    paddingRight: {
      '0': {
        paddingRight: '$space-0',
      },
      px: {
        paddingRight: '$space-px',
      },
      '0-5': {
        paddingRight: '$space-0-5',
      },
      '1': {
        paddingRight: '$space-1',
      },
      '1-5': {
        paddingRight: '$space-1-5',
      },
      '2': {
        paddingRight: '$space-2',
      },
      '3': {
        paddingRight: '$space-3',
      },
      '4': {
        paddingRight: '$space-4',
      },
      '5': {
        paddingRight: '$space-5',
      },
      '6': {
        paddingRight: '$space-6',
      },
      '7': {
        paddingRight: '$space-7',
      },
      '8': {
        paddingRight: '$space-8',
      },
      '9': {
        paddingRight: '$space-9',
      },
      '10': {
        paddingRight: '$space-10',
      },
      '11': {
        paddingRight: '$space-11',
      },
      '12': {
        paddingRight: '$space-12',
      },
      '14': {
        paddingRight: '$space-14',
      },
      '16': {
        paddingRight: '$space-16',
      },
      '20': {
        paddingRight: '$space-20',
      },
      '24': {
        paddingRight: '$space-24',
      },
      '28': {
        paddingRight: '$space-28',
      },
      '32': {
        paddingRight: '$space-32',
      },
      '36': {
        paddingRight: '$space-36',
      },
      '40': {
        paddingRight: '$space-40',
      },
      '44': {
        paddingRight: '$space-44',
      },
      '48': {
        paddingRight: '$space-48',
      },
      '52': {
        paddingRight: '$space-52',
      },
      '56': {
        paddingRight: '$space-56',
      },
      '60': {
        paddingRight: '$space-60',
      },
      '64': {
        paddingRight: '$space-64',
      },
      '72': {
        paddingRight: '$space-72',
      },
      '80': {
        paddingRight: '$space-80',
      },
      '96': {
        paddingRight: '$space-96',
      },
    },
    paddingTop: {
      '0': {
        paddingTop: '$space-0',
      },
      px: {
        paddingTop: '$space-px',
      },
      '0-5': {
        paddingTop: '$space-0-5',
      },
      '1': {
        paddingTop: '$space-1',
      },
      '1-5': {
        paddingTop: '$space-1-5',
      },
      '2': {
        paddingTop: '$space-2',
      },
      '3': {
        paddingTop: '$space-3',
      },
      '4': {
        paddingTop: '$space-4',
      },
      '5': {
        paddingTop: '$space-5',
      },
      '6': {
        paddingTop: '$space-6',
      },
      '7': {
        paddingTop: '$space-7',
      },
      '8': {
        paddingTop: '$space-8',
      },
      '9': {
        paddingTop: '$space-9',
      },
      '10': {
        paddingTop: '$space-10',
      },
      '11': {
        paddingTop: '$space-11',
      },
      '12': {
        paddingTop: '$space-12',
      },
      '14': {
        paddingTop: '$space-14',
      },
      '16': {
        paddingTop: '$space-16',
      },
      '20': {
        paddingTop: '$space-20',
      },
      '24': {
        paddingTop: '$space-24',
      },
      '28': {
        paddingTop: '$space-28',
      },
      '32': {
        paddingTop: '$space-32',
      },
      '36': {
        paddingTop: '$space-36',
      },
      '40': {
        paddingTop: '$space-40',
      },
      '44': {
        paddingTop: '$space-44',
      },
      '48': {
        paddingTop: '$space-48',
      },
      '52': {
        paddingTop: '$space-52',
      },
      '56': {
        paddingTop: '$space-56',
      },
      '60': {
        paddingTop: '$space-60',
      },
      '64': {
        paddingTop: '$space-64',
      },
      '72': {
        paddingTop: '$space-72',
      },
      '80': {
        paddingTop: '$space-80',
      },
      '96': {
        paddingTop: '$space-96',
      },
    },
    paddingBottom: {
      '0': {
        paddingBottom: '$space-0',
      },
      px: {
        paddingBottom: '$space-px',
      },
      '0-5': {
        paddingBottom: '$space-0-5',
      },
      '1': {
        paddingBottom: '$space-1',
      },
      '1-5': {
        paddingBottom: '$space-1-5',
      },
      '2': {
        paddingBottom: '$space-2',
      },
      '3': {
        paddingBottom: '$space-3',
      },
      '4': {
        paddingBottom: '$space-4',
      },
      '5': {
        paddingBottom: '$space-5',
      },
      '6': {
        paddingBottom: '$space-6',
      },
      '7': {
        paddingBottom: '$space-7',
      },
      '8': {
        paddingBottom: '$space-8',
      },
      '9': {
        paddingBottom: '$space-9',
      },
      '10': {
        paddingBottom: '$space-10',
      },
      '11': {
        paddingBottom: '$space-11',
      },
      '12': {
        paddingBottom: '$space-12',
      },
      '14': {
        paddingBottom: '$space-14',
      },
      '16': {
        paddingBottom: '$space-16',
      },
      '20': {
        paddingBottom: '$space-20',
      },
      '24': {
        paddingBottom: '$space-24',
      },
      '28': {
        paddingBottom: '$space-28',
      },
      '32': {
        paddingBottom: '$space-32',
      },
      '36': {
        paddingBottom: '$space-36',
      },
      '40': {
        paddingBottom: '$space-40',
      },
      '44': {
        paddingBottom: '$space-44',
      },
      '48': {
        paddingBottom: '$space-48',
      },
      '52': {
        paddingBottom: '$space-52',
      },
      '56': {
        paddingBottom: '$space-56',
      },
      '60': {
        paddingBottom: '$space-60',
      },
      '64': {
        paddingBottom: '$space-64',
      },
      '72': {
        paddingBottom: '$space-72',
      },
      '80': {
        paddingBottom: '$space-80',
      },
      '96': {
        paddingBottom: '$space-96',
      },
    },
    position: {
      absolute: {
        position: 'absolute',
      },
      relative: {
        position: 'relative',
      },
      fixed: {
        position: 'fixed',
      },
      sticky: {
        position: 'sticky',
      },
      static: {
        position: 'static',
      },
    },
    size: {
      '0': {
        width: '$size-0',
        height: '$size-0',
      },
      px: {
        width: '$size-px',
        height: '$size-px',
      },
      '0-5': {
        width: '$size-0-5',
        height: '$size-0-5',
      },
      '1': {
        width: '$size-1',
        height: '$size-1',
      },
      '1-5': {
        width: '$size-1-5',
        height: '$size-1-5',
      },
      '2': {
        width: '$size-2',
        height: '$size-2',
      },
      '3': {
        width: '$size-3',
        height: '$size-3',
      },
      '4': {
        width: '$size-4',
        height: '$size-4',
      },
      '5': {
        width: '$size-5',
        height: '$size-5',
      },
      '6': {
        width: '$size-6',
        height: '$size-6',
      },
      '7': {
        width: '$size-7',
        height: '$size-7',
      },
      '8': {
        width: '$size-8',
        height: '$size-8',
      },
      '9': {
        width: '$size-9',
        height: '$size-9',
      },
      '10': {
        width: '$size-10',
        height: '$size-10',
      },
      '11': {
        width: '$size-11',
        height: '$size-11',
      },
      '12': {
        width: '$size-12',
        height: '$size-12',
      },
      '14': {
        width: '$size-14',
        height: '$size-14',
      },
      '16': {
        width: '$size-16',
        height: '$size-16',
      },
      '18': {
        width: '$size-18',
        height: '$size-18',
      },
      '20': {
        width: '$size-20',
        height: '$size-20',
      },
      '22': {
        width: '$size-22',
        height: '$size-22',
      },
      '24': {
        width: '$size-24',
        height: '$size-24',
      },
      '26': {
        width: '$size-26',
        height: '$size-26',
      },
      '28': {
        width: '$size-28',
        height: '$size-28',
      },
      '30': {
        width: '$size-30',
        height: '$size-30',
      },
      '32': {
        width: '$size-32',
        height: '$size-32',
      },
      '36': {
        width: '$size-36',
        height: '$size-36',
      },
      '40': {
        width: '$size-40',
        height: '$size-40',
      },
      '44': {
        width: '$size-44',
        height: '$size-44',
      },
      '48': {
        width: '$size-48',
        height: '$size-48',
      },
      '52': {
        width: '$size-52',
        height: '$size-52',
      },
      '56': {
        width: '$size-56',
        height: '$size-56',
      },
      '60': {
        width: '$size-60',
        height: '$size-60',
      },
      '64': {
        width: '$size-64',
        height: '$size-64',
      },
      '72': {
        width: '$size-72',
        height: '$size-72',
      },
      '80': {
        width: '$size-80',
        height: '$size-80',
      },
      '88': {
        width: '$size-88',
        height: '$size-88',
      },
      '96': {
        width: '$size-96',
        height: '$size-96',
      },
      '104': {
        width: '$size-104',
        height: '$size-104',
      },
      '112': {
        width: '$size-112',
        height: '$size-112',
      },
      '120': {
        width: '$size-120',
        height: '$size-120',
      },
      '124': {
        width: '$size-124',
        height: '$size-124',
      },
      '128': {
        width: '$size-128',
        height: '$size-128',
      },
      '144': {
        width: '$size-144',
        height: '$size-144',
      },
      auto: {
        width: '$size-auto',
        height: '$size-auto',
      },
      full: {
        width: '$size-full',
        height: '$size-full',
      },
      min: {
        width: '$size-min',
        height: '$size-min',
      },
      max: {
        width: '$size-max',
        height: '$size-max',
      },
      fit: {
        width: '$size-fit',
        height: '$size-fit',
      },
    },
    width: {
      '0': {
        width: '$size-0',
      },
      px: {
        width: '$size-px',
      },
      '0-5': {
        width: '$size-0-5',
      },
      '1': {
        width: '$size-1',
      },
      '1-5': {
        width: '$size-1-5',
      },
      '2': {
        width: '$size-2',
      },
      '3': {
        width: '$size-3',
      },
      '4': {
        width: '$size-4',
      },
      '5': {
        width: '$size-5',
      },
      '6': {
        width: '$size-6',
      },
      '7': {
        width: '$size-7',
      },
      '8': {
        width: '$size-8',
      },
      '9': {
        width: '$size-9',
      },
      '10': {
        width: '$size-10',
      },
      '11': {
        width: '$size-11',
      },
      '12': {
        width: '$size-12',
      },
      '14': {
        width: '$size-14',
      },
      '16': {
        width: '$size-16',
      },
      '18': {
        width: '$size-18',
      },
      '20': {
        width: '$size-20',
      },
      '22': {
        width: '$size-22',
      },
      '24': {
        width: '$size-24',
      },
      '26': {
        width: '$size-26',
      },
      '28': {
        width: '$size-28',
      },
      '30': {
        width: '$size-30',
      },
      '32': {
        width: '$size-32',
      },
      '36': {
        width: '$size-36',
      },
      '40': {
        width: '$size-40',
      },
      '44': {
        width: '$size-44',
      },
      '48': {
        width: '$size-48',
      },
      '52': {
        width: '$size-52',
      },
      '56': {
        width: '$size-56',
      },
      '60': {
        width: '$size-60',
      },
      '64': {
        width: '$size-64',
      },
      '72': {
        width: '$size-72',
      },
      '80': {
        width: '$size-80',
      },
      '88': {
        width: '$size-88',
      },
      '96': {
        width: '$size-96',
      },
      '104': {
        width: '$size-104',
      },
      '112': {
        width: '$size-112',
      },
      '120': {
        width: '$size-120',
      },
      '124': {
        width: '$size-124',
      },
      '128': {
        width: '$size-128',
      },
      '144': {
        width: '$size-144',
      },
      auto: {
        width: '$size-auto',
      },
      full: {
        width: '$size-full',
      },
      min: {
        width: '$size-min',
      },
      max: {
        width: '$size-max',
      },
      fit: {
        width: '$size-fit',
      },
      'w-screen': {
        width: '$size-w-screen',
      },
    },
    height: {
      '0': {
        height: '$size-0',
      },
      px: {
        height: '$size-px',
      },
      '0-5': {
        height: '$size-0-5',
      },
      '1': {
        height: '$size-1',
      },
      '1-5': {
        height: '$size-1-5',
      },
      '2': {
        height: '$size-2',
      },
      '3': {
        height: '$size-3',
      },
      '4': {
        height: '$size-4',
      },
      '5': {
        height: '$size-5',
      },
      '6': {
        height: '$size-6',
      },
      '7': {
        height: '$size-7',
      },
      '8': {
        height: '$size-8',
      },
      '9': {
        height: '$size-9',
      },
      '10': {
        height: '$size-10',
      },
      '11': {
        height: '$size-11',
      },
      '12': {
        height: '$size-12',
      },
      '14': {
        height: '$size-14',
      },
      '16': {
        height: '$size-16',
      },
      '18': {
        height: '$size-18',
      },
      '20': {
        height: '$size-20',
      },
      '22': {
        height: '$size-22',
      },
      '24': {
        height: '$size-24',
      },
      '26': {
        height: '$size-26',
      },
      '28': {
        height: '$size-28',
      },
      '30': {
        height: '$size-30',
      },
      '32': {
        height: '$size-32',
      },
      '36': {
        height: '$size-36',
      },
      '40': {
        height: '$size-40',
      },
      '44': {
        height: '$size-44',
      },
      '48': {
        height: '$size-48',
      },
      '52': {
        height: '$size-52',
      },
      '56': {
        height: '$size-56',
      },
      '60': {
        height: '$size-60',
      },
      '64': {
        height: '$size-64',
      },
      '72': {
        height: '$size-72',
      },
      '80': {
        height: '$size-80',
      },
      '88': {
        height: '$size-88',
      },
      '96': {
        height: '$size-96',
      },
      '104': {
        height: '$size-104',
      },
      '112': {
        height: '$size-112',
      },
      '120': {
        height: '$size-120',
      },
      '124': {
        height: '$size-124',
      },
      '128': {
        height: '$size-128',
      },
      '144': {
        height: '$size-144',
      },
      auto: {
        height: '$size-auto',
      },
      full: {
        height: '$size-full',
      },
      min: {
        height: '$size-min',
      },
      max: {
        height: '$size-max',
      },
      fit: {
        height: '$size-fit',
      },
      'h-screen': {
        height: '$size-h-screen',
      },
      'viewport-height': {
        height: '$size-viewport-height',
      },
      'sticky-viewport-height': {
        height: '$size-sticky-viewport-height',
      },
    },
    inset: {
      '0': {
        inset: '0px',
      },
      '1': {
        inset: '1px',
      },
      '-1': {
        inset: '-1px',
      },
      'x-0': {
        left: '0px',
        right: '0px',
      },
      'y-0': {
        top: '0px',
        bottom: '0px',
      },
    },
    top: {
      '0': {
        top: '$space-0',
      },
      px: {
        top: '$space-px',
      },
      '0-5': {
        top: '$space-0-5',
      },
      '1': {
        top: '$space-1',
      },
      '1-5': {
        top: '$space-1-5',
      },
      '2': {
        top: '$space-2',
      },
      '3': {
        top: '$space-3',
      },
      '4': {
        top: '$space-4',
      },
      '5': {
        top: '$space-5',
      },
      '6': {
        top: '$space-6',
      },
      '7': {
        top: '$space-7',
      },
      '8': {
        top: '$space-8',
      },
      '9': {
        top: '$space-9',
      },
      '10': {
        top: '$space-10',
      },
      '11': {
        top: '$space-11',
      },
      '12': {
        top: '$space-12',
      },
      '14': {
        top: '$space-14',
      },
      '16': {
        top: '$space-16',
      },
      '20': {
        top: '$space-20',
      },
      '24': {
        top: '$space-24',
      },
      '28': {
        top: '$space-28',
      },
      '32': {
        top: '$space-32',
      },
      '36': {
        top: '$space-36',
      },
      '40': {
        top: '$space-40',
      },
      '44': {
        top: '$space-44',
      },
      '48': {
        top: '$space-48',
      },
      '52': {
        top: '$space-52',
      },
      '56': {
        top: '$space-56',
      },
      '60': {
        top: '$space-60',
      },
      '64': {
        top: '$space-64',
      },
      '72': {
        top: '$space-72',
      },
      '80': {
        top: '$space-80',
      },
      '96': {
        top: '$space-96',
      },
      auto: {
        top: '$space-auto',
      },
      'sticky-top': {
        top: '$space-sticky-top',
      },
    },
    bottom: {
      '0': {
        bottom: '$space-0',
      },
      px: {
        bottom: '$space-px',
      },
      '0-5': {
        bottom: '$space-0-5',
      },
      '1': {
        bottom: '$space-1',
      },
      '1-5': {
        bottom: '$space-1-5',
      },
      '2': {
        bottom: '$space-2',
      },
      '3': {
        bottom: '$space-3',
      },
      '4': {
        bottom: '$space-4',
      },
      '5': {
        bottom: '$space-5',
      },
      '6': {
        bottom: '$space-6',
      },
      '7': {
        bottom: '$space-7',
      },
      '8': {
        bottom: '$space-8',
      },
      '9': {
        bottom: '$space-9',
      },
      '10': {
        bottom: '$space-10',
      },
      '11': {
        bottom: '$space-11',
      },
      '12': {
        bottom: '$space-12',
      },
      '14': {
        bottom: '$space-14',
      },
      '16': {
        bottom: '$space-16',
      },
      '20': {
        bottom: '$space-20',
      },
      '24': {
        bottom: '$space-24',
      },
      '28': {
        bottom: '$space-28',
      },
      '32': {
        bottom: '$space-32',
      },
      '36': {
        bottom: '$space-36',
      },
      '40': {
        bottom: '$space-40',
      },
      '44': {
        bottom: '$space-44',
      },
      '48': {
        bottom: '$space-48',
      },
      '52': {
        bottom: '$space-52',
      },
      '56': {
        bottom: '$space-56',
      },
      '60': {
        bottom: '$space-60',
      },
      '64': {
        bottom: '$space-64',
      },
      '72': {
        bottom: '$space-72',
      },
      '80': {
        bottom: '$space-80',
      },
      '96': {
        bottom: '$space-96',
      },
      auto: {
        bottom: '$space-auto',
      },
    },
    left: {
      '0': {
        left: '$space-0',
      },
      px: {
        left: '$space-px',
      },
      '0-5': {
        left: '$space-0-5',
      },
      '1': {
        left: '$space-1',
      },
      '1-5': {
        left: '$space-1-5',
      },
      '2': {
        left: '$space-2',
      },
      '3': {
        left: '$space-3',
      },
      '4': {
        left: '$space-4',
      },
      '5': {
        left: '$space-5',
      },
      '6': {
        left: '$space-6',
      },
      '7': {
        left: '$space-7',
      },
      '8': {
        left: '$space-8',
      },
      '9': {
        left: '$space-9',
      },
      '10': {
        left: '$space-10',
      },
      '11': {
        left: '$space-11',
      },
      '12': {
        left: '$space-12',
      },
      '14': {
        left: '$space-14',
      },
      '16': {
        left: '$space-16',
      },
      '20': {
        left: '$space-20',
      },
      '24': {
        left: '$space-24',
      },
      '28': {
        left: '$space-28',
      },
      '32': {
        left: '$space-32',
      },
      '36': {
        left: '$space-36',
      },
      '40': {
        left: '$space-40',
      },
      '44': {
        left: '$space-44',
      },
      '48': {
        left: '$space-48',
      },
      '52': {
        left: '$space-52',
      },
      '56': {
        left: '$space-56',
      },
      '60': {
        left: '$space-60',
      },
      '64': {
        left: '$space-64',
      },
      '72': {
        left: '$space-72',
      },
      '80': {
        left: '$space-80',
      },
      '96': {
        left: '$space-96',
      },
      auto: {
        left: '$space-auto',
      },
    },
    right: {
      '0': {
        right: '$space-0',
      },
      px: {
        right: '$space-px',
      },
      '0-5': {
        right: '$space-0-5',
      },
      '1': {
        right: '$space-1',
      },
      '1-5': {
        right: '$space-1-5',
      },
      '2': {
        right: '$space-2',
      },
      '3': {
        right: '$space-3',
      },
      '4': {
        right: '$space-4',
      },
      '5': {
        right: '$space-5',
      },
      '6': {
        right: '$space-6',
      },
      '7': {
        right: '$space-7',
      },
      '8': {
        right: '$space-8',
      },
      '9': {
        right: '$space-9',
      },
      '10': {
        right: '$space-10',
      },
      '11': {
        right: '$space-11',
      },
      '12': {
        right: '$space-12',
      },
      '14': {
        right: '$space-14',
      },
      '16': {
        right: '$space-16',
      },
      '20': {
        right: '$space-20',
      },
      '24': {
        right: '$space-24',
      },
      '28': {
        right: '$space-28',
      },
      '32': {
        right: '$space-32',
      },
      '36': {
        right: '$space-36',
      },
      '40': {
        right: '$space-40',
      },
      '44': {
        right: '$space-44',
      },
      '48': {
        right: '$space-48',
      },
      '52': {
        right: '$space-52',
      },
      '56': {
        right: '$space-56',
      },
      '60': {
        right: '$space-60',
      },
      '64': {
        right: '$space-64',
      },
      '72': {
        right: '$space-72',
      },
      '80': {
        right: '$space-80',
      },
      '96': {
        right: '$space-96',
      },
      auto: {
        right: '$space-auto',
      },
    },
    overflow: {
      auto: {
        overflow: 'auto',
      },
      hidden: {
        overflow: 'hidden',
      },
      scroll: {
        overflow: 'scroll',
      },
      visible: {
        overflow: 'visible',
      },
    },
    zIndex: {
      '0': {
        zIndex: '$0',
      },
      '1': {
        zIndex: '$1',
      },
      '2': {
        zIndex: '$2',
      },
      '3': {
        zIndex: '$3',
      },
      '4': {
        zIndex: '$4',
      },
      '5': {
        zIndex: '$5',
      },
      '6': {
        zIndex: '$6',
      },
      '7': {
        zIndex: '$7',
      },
      '8': {
        zIndex: '$8',
      },
      '9': {
        zIndex: '$9',
      },
      '10': {
        zIndex: '$10',
      },
      header: {
        zIndex: '$header',
      },
      floating: {
        zIndex: '$floating',
      },
      panel: {
        zIndex: '$panel',
      },
      overlay: {
        zIndex: '$overlay',
      },
      modal: {
        zIndex: '$modal',
      },
    },
    boxShadow: {
      none: {
        boxShadow: 'none',
      },
      '100': {
        boxShadow: '$shadow-100',
      },
      '200': {
        boxShadow: '$shadow-200',
      },
      '300': {
        boxShadow: '$shadow-300',
      },
      '400': {
        boxShadow: '$shadow-400',
      },
      left: {
        boxShadow: '$shadow-left',
      },
      right: {
        boxShadow: '$shadow-right',
      },
    },
    pointerEvents: {
      none: {
        pointerEvents: 'none',
      },
      auto: {
        pointerEvents: 'auto',
      },
    },
    userSelect: {
      auto: {
        userSelect: 'auto',
      },
      none: {
        userSelect: 'none',
      },
    },
    fontSize: {
      '0': {
        fontSize: '0px',
      },
    },
    textAlign: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
      justify: {
        textAlign: 'justify',
      },
    },
    cursor: {
      pointer: {
        cursor: 'pointer',
      },
      default: {
        cursor: 'default',
      },
    },
    visibility: {
      hidden: {
        visibility: 'hidden',
      },
      visible: {
        visibility: 'visible',
      },
    },
    opacity: {
      '0': {
        opacity: '0',
      },
      '1': {
        opacity: '1',
      },
    },
    display: {
      block: {
        display: 'block',
      },
      'inline-block': {
        display: 'inline-block',
      },
      inline: {
        display: 'inline',
      },
      flex: {
        display: 'flex',
      },
      'inline-flex': {
        display: 'inline-flex',
      },
      grid: {
        display: 'grid',
      },
      'inline-grid': {
        display: 'inline-grid',
      },
      none: {
        display: 'none',
      },
    },
    direction: {
      row: {
        flexDirection: 'row',
      },
      'row-reverse': {
        flexDirection: 'row-reverse',
      },
      column: {
        flexDirection: 'column',
      },
      'column-reverse': {
        flexDirection: 'column-reverse',
      },
    },
    alignItems: {
      start: {
        alignItems: 'flex-start',
      },
      end: {
        alignItems: 'flex-end',
      },
      center: {
        alignItems: 'center',
      },
      baseline: {
        alignItems: 'baseline',
      },
      stretch: {
        alignItems: 'stretch',
      },
    },
    justifyContent: {
      start: {
        justifyContent: 'flex-start',
      },
      end: {
        justifyContent: 'flex-end',
      },
      center: {
        justifyContent: 'center',
      },
      around: {
        justifyContent: 'space-around',
      },
      between: {
        justifyContent: 'space-between',
      },
      evenly: {
        justifyContent: 'space-evenly',
      },
      stretch: {
        justifyContent: 'stretch',
      },
    },
    justifyItems: {
      center: {
        justifyItems: 'center',
      },
      start: {
        justifyItems: 'start',
      },
      end: {
        justifyItems: 'end',
      },
      baseline: {
        justifyItems: 'baseline',
      },
      stretch: {
        justifyItems: 'stretch',
      },
    },
    flexWrap: {
      nowrap: {
        flexWrap: 'nowrap',
      },
      wrap: {
        flexWrap: 'wrap',
      },
      'wrap-reverse': {
        flexWrap: 'wrap-reverse',
      },
    },
    gap: {
      '0': {
        gap: '$space-0',
      },
      px: {
        gap: '$space-px',
      },
      '0-5': {
        gap: '$space-0-5',
      },
      '1': {
        gap: '$space-1',
      },
      '1-5': {
        gap: '$space-1-5',
      },
      '2': {
        gap: '$space-2',
      },
      '3': {
        gap: '$space-3',
      },
      '4': {
        gap: '$space-4',
      },
      '5': {
        gap: '$space-5',
      },
      '6': {
        gap: '$space-6',
      },
      '7': {
        gap: '$space-7',
      },
      '8': {
        gap: '$space-8',
      },
      '9': {
        gap: '$space-9',
      },
      '10': {
        gap: '$space-10',
      },
      '11': {
        gap: '$space-11',
      },
      '12': {
        gap: '$space-12',
      },
      '14': {
        gap: '$space-14',
      },
      '16': {
        gap: '$space-16',
      },
      '20': {
        gap: '$space-20',
      },
      '24': {
        gap: '$space-24',
      },
      '28': {
        gap: '$space-28',
      },
      '32': {
        gap: '$space-32',
      },
      '36': {
        gap: '$space-36',
      },
      '40': {
        gap: '$space-40',
      },
      '44': {
        gap: '$space-44',
      },
      '48': {
        gap: '$space-48',
      },
      '52': {
        gap: '$space-52',
      },
      '56': {
        gap: '$space-56',
      },
      '60': {
        gap: '$space-60',
      },
      '64': {
        gap: '$space-64',
      },
      '72': {
        gap: '$space-72',
      },
      '80': {
        gap: '$space-80',
      },
      '96': {
        gap: '$space-96',
      },
    },
    columnGap: {
      '0': {
        columnGap: '$space-0',
      },
      px: {
        columnGap: '$space-px',
      },
      '0-5': {
        columnGap: '$space-0-5',
      },
      '1': {
        columnGap: '$space-1',
      },
      '1-5': {
        columnGap: '$space-1-5',
      },
      '2': {
        columnGap: '$space-2',
      },
      '3': {
        columnGap: '$space-3',
      },
      '4': {
        columnGap: '$space-4',
      },
      '5': {
        columnGap: '$space-5',
      },
      '6': {
        columnGap: '$space-6',
      },
      '7': {
        columnGap: '$space-7',
      },
      '8': {
        columnGap: '$space-8',
      },
      '9': {
        columnGap: '$space-9',
      },
      '10': {
        columnGap: '$space-10',
      },
      '11': {
        columnGap: '$space-11',
      },
      '12': {
        columnGap: '$space-12',
      },
      '14': {
        columnGap: '$space-14',
      },
      '16': {
        columnGap: '$space-16',
      },
      '20': {
        columnGap: '$space-20',
      },
      '24': {
        columnGap: '$space-24',
      },
      '28': {
        columnGap: '$space-28',
      },
      '32': {
        columnGap: '$space-32',
      },
      '36': {
        columnGap: '$space-36',
      },
      '40': {
        columnGap: '$space-40',
      },
      '44': {
        columnGap: '$space-44',
      },
      '48': {
        columnGap: '$space-48',
      },
      '52': {
        columnGap: '$space-52',
      },
      '56': {
        columnGap: '$space-56',
      },
      '60': {
        columnGap: '$space-60',
      },
      '64': {
        columnGap: '$space-64',
      },
      '72': {
        columnGap: '$space-72',
      },
      '80': {
        columnGap: '$space-80',
      },
      '96': {
        columnGap: '$space-96',
      },
    },
    rowGap: {
      '0': {
        rowGap: '$space-0',
      },
      px: {
        rowGap: '$space-px',
      },
      '0-5': {
        rowGap: '$space-0-5',
      },
      '1': {
        rowGap: '$space-1',
      },
      '1-5': {
        rowGap: '$space-1-5',
      },
      '2': {
        rowGap: '$space-2',
      },
      '3': {
        rowGap: '$space-3',
      },
      '4': {
        rowGap: '$space-4',
      },
      '5': {
        rowGap: '$space-5',
      },
      '6': {
        rowGap: '$space-6',
      },
      '7': {
        rowGap: '$space-7',
      },
      '8': {
        rowGap: '$space-8',
      },
      '9': {
        rowGap: '$space-9',
      },
      '10': {
        rowGap: '$space-10',
      },
      '11': {
        rowGap: '$space-11',
      },
      '12': {
        rowGap: '$space-12',
      },
      '14': {
        rowGap: '$space-14',
      },
      '16': {
        rowGap: '$space-16',
      },
      '20': {
        rowGap: '$space-20',
      },
      '24': {
        rowGap: '$space-24',
      },
      '28': {
        rowGap: '$space-28',
      },
      '32': {
        rowGap: '$space-32',
      },
      '36': {
        rowGap: '$space-36',
      },
      '40': {
        rowGap: '$space-40',
      },
      '44': {
        rowGap: '$space-44',
      },
      '48': {
        rowGap: '$space-48',
      },
      '52': {
        rowGap: '$space-52',
      },
      '56': {
        rowGap: '$space-56',
      },
      '60': {
        rowGap: '$space-60',
      },
      '64': {
        rowGap: '$space-64',
      },
      '72': {
        rowGap: '$space-72',
      },
      '80': {
        rowGap: '$space-80',
      },
      '96': {
        rowGap: '$space-96',
      },
    },
  },
});

export interface BoxProps extends React.ComponentProps<typeof BoxStyled> {
  /**
   * The component to be rendered.
   */
  component?: ElementType;
  /**
   * The content to be rendered inside the component.
   */
  children?: ReactNode;
  /**
   * Additional CSS for the component.
   */
  css?: CSS | undefined;
  /**
   * Additional class name for the component.
   */
  className?: string;
  /**
   * The id of the component.
   */
  id?: string;
  /**
   * The role of the component.
   */
  role?: string;
  /**
   * The title of the component.
   */
  title?: string;
  /**
   * The aria-label of the component.
   */
  ariaLabel?: string;
  /**
   * The tracking data for the component.
   */
  tracking?: ITracking;
  /**
   * The test data for the component.
   */
  test?: ITest;
  /**
   * The click event handler for the component.
   */
  onClick?: () => void;
  /**
   * The hover text for the component.
   */
  hoverText?: string;
  /**
   * The background color on hover for the component.
   */
  hoverBg?: string;
  /**
   * The child color on hover for the component.
   */
  hoverChild?: string;
}

export const Box = forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      component = 'div',
      children,
      css,
      className,
      id,
      role,
      title,
      ariaLabel,
      tracking,
      test,
      onClick,
      hoverText,
      hoverBg,
      hoverChild,
      ...rest
    },
    ref
  ) => {
    return (
      <BoxStyled
        as={component}
        css={css}
        className={className}
        id={id}
        role={role}
        aria-label={ariaLabel || title || undefined}
        onClick={onClick}
        data-track={tracking?.dataTrack}
        data-track-section={tracking?.dataTrackSection}
        data-track-value={tracking?.dataTrackValue}
        data-track-text={tracking?.dataTrackText}
        data-track-url={tracking?.dataTrackUrl}
        data-test={test?.dataTest}
        data-test-value={test?.dataTestValue}
        data-test-text={test?.dataTestText}
        data-test-href={test?.dataTestHref}
        data-test-category={test?.dataTestCategory}
        data-test-price={test?.dataTestPrice}
        data-test-business-type={test?.dataTestBusiness}
        data-test-segment-type={test?.dataTestSegment}
        data-test-body-type={test?.dataTestBody}
        data-test-tag={test?.dataTestTag}
        data-test-update-date={test?.dataTestUpdateDate}
        data-test-pin={test?.dataTestPin}
        data-hover-text={hoverText}
        data-hover-bg={hoverBg}
        data-hover-child={hoverChild}
        ref={ref}
        {...rest}
      >
        {children}
      </BoxStyled>
    );
  }
);

Box.displayName = 'Box';
